import React from "react";
import Video from "../components/Video";
import Mainform from "../components/Mainform";
import { Parallax } from "react-parallax";
import './Homes.css';
import Family from "../components/Family";
import Sliders from "../components/Sliders";
import Footer from "../components/Footer";

function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", reveal);

  const handleTop = () => {
    window.scrollTo({
      top: 1240,
      behavior: "smooth"
    });}

const Homes = ({userObj}) => {
    
return(
    <div>

    <div className="fheader">

      <div className="fheader-family">

      <a href="/">
      <div className="fheader-family-logo"></div>
      </a>

      <a href="/">
      <div className="fheader-family-listC"></div>
      </a>

      <a href="https://jungdari.com/"
        target="_blank"
        rel="noopener noreferrer">
      <div className="fheader-family-listB"></div>
      </a>

      <a href="https://www.darlipage.com/"
        target="_blank"
        rel="noopener noreferrer">
      <div className="fheader-family-listA"></div>
      </a>

      <a href="https://darlidata.com/"
        target="_blank"
        rel="noopener noreferrer">
      <div className="fheader-family-listD"></div>
      </a>

      </div>

    </div>

<div className='contentsss'>
                <h1>
                    Be different<br />Be dramatic<br />Be daring
                </h1>
            <div className='btnsss' onClick={handleTop}>
                물류 신청하기
            </div>
            </div>
    
       
            <section className="area1">
            <Parallax>

                 <Video />

                 </Parallax>
            </section>
            
            <div className="tost">

            <div className="totalwrap">

            <div className="full">
            <section className="area2">
            <Parallax>

            <Mainform userObj={userObj}/>

            </Parallax>
            </section>

            <div className="totalwraps">

            <div className="fulls">
            <section className="area3">
            <Parallax>
                <Sliders/>
            </Parallax>
            </section>
            </div>
            </div>

            <section className="area4">
                <Family />
            </section>
            </div>
            <Footer />
            </div>
            </div>
       



    </div>

)
}

export default Homes;