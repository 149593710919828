import React from 'react';
import './Mainform.css';
import Airpro from '../assets/airpro.svg'
import Fclpro from '../assets/fclpro.svg'
import Lclpro from '../assets/lclpro.svg'
import { Link } from "react-router-dom";





function Mainform( ) {













    return (
            <div className='section-wrappersa'>
                                  <div className="contens">
                                 <div className='reveal fade-bottom'>International Logistics<br/>Total Care Service </div>
                                 </div>
                                 <div className='mobbb'>
                                 <div className='reveal fade-bottom'>International Logistics Total Care Service</div>
                                 </div>
                	<ul className='uls'>
                  <Link to="/airform">
                      <li className='lis'>
                        <div className="product-1">
                        <div className="card-1">
                          <div className="photo"></div>
                          <div className="content">
                          <h1 className="titles">항공 특송</h1>
                          <div className="features">
                            <h3>소량으로 보내기 적합하며,<br/>타 운송에 비해 운송기간이 짧아<br/> 빠른 배송이 가능합니다. </h3>
                          </div>
                          <button className="btn-buy">견적 요청</button>
                          </div>
                        </div>
                        </div>
                      </li>
                      </Link>

                      <Link to="/fclform">
                      <li className='lis'>
                        <div className="product-2">
                        <div className="card-2">
                          <div className="photo"></div>
                          <div className="content">
                          <h1 className="titles">해운 FCL</h1>
                          <div className="features">
                            <h3>컨테이너 한 대를 독점해서<br/> 물건을 적재하며,<br/>많은 물량을 실어 보낼 수 있습니다. </h3>
                          </div>
                          <button className="btn-buy">견적 요청</button>
                          </div>
                        </div>
                        </div>
                      </li>
                      </Link>

                      <Link to="/lclform">
                      <li className='lis'>
                        <div className="product-3">
                        <div className="card-3">
                          <div className="photo"></div>
                          <div className="content">
                          <h1 className="titles">해운 LCL</h1>
                          <div className="features">
                            <h3>컨테이너 한 대에 여러 화주의<br/> 물건을 함께 적재하며,<br/>물량으로 보내기 적합합니다.</h3>
                          </div>
                          <button className="btn-buy" >견적 요청</button>
                          </div>
                        </div>
                        </div>
                      </li>
                      </Link>
                      
                      </ul>

                      <div className='ulss'>
                      <Link to="/airform">
                        <div className='propro'>
                            <img src={Airpro} alt="air image" className='prologo'/>
                            <div className='proti'>
                              항공특송
                            </div>
                            
                            <div className='protro'>
                              소량으로 보내기에 적합합니다.
                            </div>
                        </div>
                        </Link>
                        <Link to="/fclform">
                        <div className='propro'>
                              <img src={Fclpro} alt='fcl image' className='prologo'/>
                            <div className='proti'>
                            해운 FCL
                            </div>
                            <div className='protro'>
                              컨테이너에 물건을 다 채울 수 있는 양이어야합니다.
                            </div>
                        </div>
                        </Link>

                        <Link to="/lclform">
                        <div className='propro'>
                              <img src={Lclpro} alt='lcl image' className='prologo'/>
                              <div className='proti'>
                              해운 LCL
                            </div>
                            <div className='protro'>
                              컨테이너를 다 채울 필요가 없습니다.
                            </div>
                        </div>
                        </Link>

                      </div>


                      <div className="conten">
                      <div className="reveal fade-bottom">포워딩을 "쉽고, 빠르고, 저렴하게" 나만의 포워딩 파트너 달리로지스틱 포워딩은<br/>늘 대량화물만 취급한다는 편견을 깨겠습니다.</div>
                                </div>
                               
            </div>



    );
}
export default Mainform;