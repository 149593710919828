import { Component } from 'react';

class PluginComponent extends Component {
  componentDidMount() {
    this.injectPlugin();
  }

  injectPlugin = () => {
    const pluginContainer = document.createElement('div');
    pluginContainer.setAttribute("id", "plugin");
    pluginContainer.style.cssText = 'position:fixed;right:25px;bottom:150px;z-index:99999 !important';

    window.shipgoPlugin = {
      pluginKey: 'ODgxNzUwNmYtODU0Mi0zZmMwLWFjZTUtYjE1ZTM3ZmIzNmIw'
    };

    const script = document.createElement('script');
    script.type = 'module';
    script.async = true;
    script.src = 'https://cdn.tradlinx.com/shipgo/plugin/shipgo-plugin-web.js';
    pluginContainer.appendChild(script);

    document.body.appendChild(pluginContainer);
  }

  render() {
    return null;
  }
}

export default PluginComponent;