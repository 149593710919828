import React, { useEffect, useState } from 'react';
import AppRouter from "./Router";
import {authService} from "../firebase";
import './App.css';
import ChannelService from "./ChannelService";
import PluginComponent from './PluginComponent'; 


const ChannelTalk = () => {
  useEffect(() => {
    ChannelService.boot({
      "pluginKey": "b9dbbc35-8802-445a-b23a-a34b9831014d",
    });
  }, []);

  return ""
}


function App() {
  const [init, setInit] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userObj, setUserObj] = useState(null);
  useEffect(() => {
    authService.onAuthStateChanged((user) => {
      if(user){
        setIsLoggedIn(true);
        setUserObj(user);
      } else {
        setIsLoggedIn(false);
        setUserObj(null)
      }
        setInit(true);
    });
    
  }, []);
  return (
  <>
  {init ? <AppRouter isLoggedIn={isLoggedIn} userObj={userObj} /> : "잠시만 기다려주세요.."}
  <PluginComponent/>
  <ChannelTalk/>
  </>
  );
}

export default App;
