import React from 'react';
import './Family.css';
import Jung from '../assets/jung.svg';
import Darlida from '../assets/darlida.svg'

function Family() {
return (
    <div className='final'>
    <div className="section-wrapperss">
        <div className='family'>
        <p>Family site</p>
        <a href="https://jungdari.com/" target="_blank" aria-label='move to family website'>
            <div className='jungdari'>
                <div className='familylogo'>
                    <img src={Jung} alt='family website logo'/>
                </div>
                <div className='intro'>
                        중국무역, 공장섭외, 알리바바/타오바오구매대행, 이우시장조사,<br/>시장조사단, 대량주문, 도매업체, 아이템 소싱 등<br/> 중국과 관련한 모든 무역이야기
                    </div>
            </div>
        </a>
        <a href="https://darlidata.com/" target="_blank" aria-label='move to family website'>
        <div className='darlidata'>
              <div className='familylogo'>
                <img src={Darlida} alt='family website logo'/>
            </div>
        <div className='intro'>
                    키워드별 네이버 판매량 수집, 연관검색어, 카테고리별 통계 등<br/> 아이템 소싱시 시행착오를 줄여주기 위한 인사이트의 보고
                </div>
                </div>
                </a>
        </div>
        
    </div>
    </div>
)

}

export default Family;