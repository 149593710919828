import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./Sliders.css";

// import required modules
import { Pagination, Navigation } from "swiper";

export default function App() {
  const [swiperRef, setSwiperRef] = useState(null);
    const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<button class="' + className + '">' + (index + 1) + "</button>";
    },
  };

  return (
    <>
      <div className="rollimgs">
                <Slider dots infinite>
                <div className="slaonep" alt="Slide 1"><div className="slaonepp"><h3 className="reveal fade-left">1인 기업도 가능한 국제물류</h3>
                <p className="reveal fade-left">한 박스라도 소중하게 고객의 제품을<br/>통관부터 배송까지 책임지는<br/>"국제물류 토탈 케어 서비스"입니다.</p></div></div>
                <div className="slatwop" alt="Slide 2"><div className="slaonepp"><h3 className="reveal fade-left">나만의 물류 파트너</h3>
                <p className="reveal fade-left">달리로지스틱만의 1:1 물류 매니저 배정은<br/>고객사의 빠른 커뮤니케이션을 가능하게 합니다.</p></div></div>
                <div className="slathreep" alt="Slide 3"><div className="slaonepp"><h3 className="reveal fade-left">고객 제일주의 전문 CS</h3>
                <p className="reveal fade-left">다양한 문의엔 즉각적인 답변을 해드리며<br/> 친절, 신속함을 최우선으로 19년 차 물류 경험 매뉴얼을 <br/>공유해 담당 매니저가 전문적으로 대응합니다.</p></div></div>
                <div className="slafourp" alt="Slide 4"><div className="slaonepp"><h3 className="reveal fade-left">혼자서도 가능한 국제물류</h3>
                <p className="reveal fade-left">선박, 항공기 부킹부터 제품 선적, 통관, 운송까지<br/>전문 물류 토탈 케어 서비스로 달리로지스틱이<br/>다 해드립니다.</p></div></div>
          </Slider>
      </div>

    <div className="wrape">

      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={3}
        centeredSlides={true}
        spaceBetween={900}
        pagination={pagination}
        navigation={true}
        loop={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >

        <div className="swrap">


        <SwiperSlide>
          <div className="test1">
          <div className="eee">
          <div className="slionecon"><div className="reveal fade-left">1인 기업도 가능한 국제물류</div></div>
        <div classname="iii"><div className="reveal fade-left">한 박스라도 소중하게 고객의 제품을 통관부터 배송까지 책임지는<br/>"국제물류 토탈 케어 서비스"입니다.</div></div>
        </div>
        </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="test2">
        <div className="eee">
          <div className="slionecon"><div className="reveal fade-left">나만의 물류 파트너</div></div>
          <div classname="iii"><div className="reveal fade-left">달리로지스틱만의 1:1 물류 매니저 배정은<br/>고객사의 빠른 커뮤니케이션을 가능하게 합니다.</div></div>
          </div>
          </div>
          </SwiperSlide>

        <SwiperSlide>
          <div className="test3">
        <div className="eee">
          <div className="slionecon"><div className="reveal fade-left">고객 제일주의의 차별화된 전문 CS</div></div>
          <div classname="iii"><div className="reveal fade-left">다양한 문의엔 즉각적인 답변을 해드리며 어떤 상황에서도 친절, 신속함을 <br/>최우선으로 하며 19년 차 물류 경험 매뉴얼을 공유해 담당 매니저가 전문적으로 대응합니다.</div></div>
          </div>
          </div>
          </SwiperSlide>
        <SwiperSlide><div className="test4">
        <div className="eee">
          <div className="slionecon"><div className="reveal fade-left">혼자서도 가능한 국제물류</div></div>
          <div classname="iii"><div className="reveal fade-left">선박, 항공기 부킹부터 제품 선적, 통관, 운송까지<br/>전문 물류 토탈 케어 서비스로 달리로지스틱이 다 해드립니다.</div></div>
          </div>
          </div>


          </SwiperSlide>

        </div>

      </Swiper>
      
      <div className="lefte"></div>
      <div className="righte"></div>

    </div>
    </>
  );
}
