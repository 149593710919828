import React from 'react';
import './Footer.css';
import Foos from '../assets/foot.svg'
import { Link } from "react-router-dom";

function Footer() {

    const godmin = (e) => {window.location.href= 'https://main.d28amcv0jstbcv.amplifyapp.com/#/';
    // e는 onClick할때 인자로 묶어보낸 소프트웨어명이다.
};

    return (
        <div className='footer'>

        <section className="f__line">
        </section>

            <div className='footer-container'>
            <div className='fot'>
                <div className='fot-ti'><Link to="/term">이용약관</Link></div>ㅣ<div className='fot-te'><Link to="/personal">개인정보처리방침</Link></div>
            </div>
                <div className='left'>
                    <div>
                    <p>경기도 부천시 조마루로 385번길 122 삼보테크노타워 611호 ㈜달리로지스틱<br/>
                    사업자번호 628-87-02700&nbsp;&nbsp;&nbsp;l&nbsp;&nbsp;&nbsp;통신판매신고 제 2023-경기부천-0899호<br/>
                    대표 이중엽&nbsp;&nbsp;&nbsp;l&nbsp;&nbsp;&nbsp;global@darlilogis.com&nbsp;&nbsp;&nbsp;l&nbsp;&nbsp;&nbsp;문의번호 070-4034-6924</p>
                    </div>
                    <div className='location'>
                    <p> Copyright {new Date().getFullYear()}© Darlilogistic.All Rights reserved</p>
                    </div>
                 </div>


                 <div className='right'>
                    <div className='foologo'>
                     <img className='footgo' onClick={(e)=>godmin(e)} src={Foos} alt='footer logo'/>
                     </div>
                 </div>
            
            </div>
        </div>
    )
}

export default Footer;