import React from 'react';
import './VideoStyles.css';
import videobg from '../assets/videobg.mp4'
import mobbg from '../assets/mob_bg.jpg'

function Video() {


    return (
        <div>
        <div className='video-cover'></div>
        <div className='hero'>
            <video className='video' autoPlay loop muted>
                <source src={videobg} type='video/mp4' />
            </video>
        </div>
        <div className='mobbg'>
                <img src={mobbg} alt='replace image' className="mobbgg"/>
            </div>
        </div>
    );
}

export default Video;