import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Lclform.css';
import { dbService } from "../firebase";
import {serverTimestamp} from "firebase/firestore";
import Footer from './Footer';
import { Link } from 'react-router-dom';
import Agreebox from "./Agreebox";
import emailjs from '@emailjs/browser';

function Lclform() {

  const [type, setType] = useState("해운 LCL"); //이름

  const [name, setName] = useState(""); //이름
  const [email, setEmail] = useState(""); //이메일
  const [phone, setPhone] = useState(""); //연락처
  const [product, setProduct] = useState(""); //제품명
  const [qty, setQty] = useState(""); //수량
  const [packageType, setPackageType] = useState(""); //포장타입
  const [kg, setKg] = useState(""); //무게
  const [size, setSize] = useState(""); //사이즈
  const [departure, setDeparture] = useState(""); //출발지
  const [arrival, setArrival] = useState(""); //도착지
  const [incoterms, setIncoterms] = useState(""); //인코텀즈
  const [note, setNote] = useState(""); //문의사항

  const [agree, setAgree] = useState(false); //개인정보
  const [ad, setAd] = useState(false); //광고성

  const [loading, setLoading] = useState(false); // 로딩 상태 추가
  const navigate = useNavigate();


  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // 로딩 시작

    if (!agree || !ad) {
        alert("유의사항에 동의해주세요");
        setLoading(false);
        return;
    } else if (!name || !email || !phone || !product || !qty || !packageType || !kg || !size || !departure || !arrival ) {
        alert("필수 항목을 모두 입력해주세요");
        setLoading(false);
        return;
    } 

    try {
      await dbService.collection("lclform").add({
        type: type,
        name: name,
        email: email,
        phone: phone,
        product: product,
        qty: qty,
        packageType: packageType,
        kg: kg,
        size: size,
        departure: departure,
        arrival: arrival,
        incoterms: incoterms,
        agree: agree,
        ad: ad,
        createdAt: Date.now(),
        join: serverTimestamp(),
        note:note
    });

    
        const templateParams = {
          type,
          name,
          email,
          phone,
          product,
          qty,
          packageType,
          kg,
          size,
          departure,
          arrival,
          incoterms,
          join: serverTimestamp(),
          note,
      };

      emailjs.send('service_unncc1p', 'template_pa7taks', templateParams, 'NlFp9-aXF9qB90y4m')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      alert('신청이 완료되었습니다.');
      navigate('/'); // 로그인 페이지로 리디렉션
    }finally {
      setLoading(false); // 로딩 종료
    }

  }


            //핸드폰
            const handlePhoneChange = (e) => {
              const phoneNum = e.target.value;
              setPhone(phoneNum
                .replace(/[^0-9]/g, '')
                .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
                .replace(/(\-{1,2})$/g, "")
              );
            }
    
 
    return (
      <div className='asd'>

<div className='introcona'>

<div style={{width:"100%", marginTop:"10%", borderRadius:"15px", fontSize:"1.3rem"}}>

<div style={{width:"100%", height:"80px", display:"flex"}}>
<div style={{width:"100%", textAlign:"center", fontSize:"30px", fontWeight:"700", margin:"auto"}}>해운LCL 신청하기</div>
</div>

<form onSubmit={onSubmit}>

<div style={{width:"100%", height:"80px", display:"flex"}}>
<div style={{width:"100%", margin:"auto 0", paddingLeft:"24px", fontSize:"24px", fontWeight:"700", borderBottom:"2px solid #b1d2fd", paddingBottom:"16px", color:"#b1d2fd"}}>신청인 정보</div>
</div>

<div style={{width:"100%", height:"80px", display:"flex"}}>
  <input
  style={{margin:"auto", width:"940px", height:"34px", borderRadius:"8px", fontSize:"16px", paddingLeft:"15px"}}
    type="text"
    placeholder="*이름 및 사업체명"
    value={name}
    onChange={(event) => setName(event.target.value)}
  />
</div>

<div className='twopart' style={{width:"100%", height:"80px"}}>

    <div style={{flex:1, display:"flex"}}>
      <input
        style={{margin:"auto", height:"34px", borderRadius:"8px", fontSize:"16px", paddingLeft:"15px"}}
        type="email"
        placeholder="*인증대행이메일"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
    </div>
    <div style={{flex:1, display:"flex"}}>
    <input
        style={{margin:"auto", height:"34px", borderRadius:"8px", fontSize:"16px", paddingLeft:"15px"}}
        type="text"
        placeholder="*연락처"
        value={phone}
        maxLength={13} 
        onChange={handlePhoneChange}
      />
    </div>
  
</div>

<div style={{width:"100%", height:"80px", display:"flex"}}>
<div style={{width:"100%", margin:"auto 0", paddingLeft:"24px", fontSize:"24px", fontWeight:"700", borderBottom:"2px solid #b1d2fd", paddingBottom:"16px", color:"#b1d2fd"}}>화물 정보</div>
</div>

<div style={{width:"100%", height:"80px", display:"flex"}}>
<input
   style={{margin:"auto", width:"940px", height:"34px", borderRadius:"8px", fontSize:"16px", paddingLeft:"15px"}}
    type="text"
    placeholder="*제품명"
    value={product}
    onChange={(event) => setProduct(event.target.value)}
  />
</div>

<div style={{width:"100%", height:"80px", display:"flex"}}>

  <select 
  style={{margin:"auto", width:"957px", height:"38px", borderRadius:"8px", fontSize:"16px", paddingLeft:"10px", color:"grey"}}
  type="text"
  value={packageType}
  onChange={(event) => {setPackageType(event.target.value);
  }}>
  <option selected="selected">*포장타입</option>
  <option>박스</option>
  <option>팔레트</option>
  <option>컨테이너</option>
  <option>기타</option>
  </select>

</div>

<div style={{width:"100%", height:"80px", display:"flex"}}>
 <input
   style={{margin:"auto", width:"940px", height:"34px", borderRadius:"8px", fontSize:"16px", paddingLeft:"15px"}}
    type="text"
    placeholder="*포장수량"
    value={qty}
    onChange={(event) => setQty(event.target.value)}
  />
</div>

<div className='twopart' style={{width:"100%", height:"80px"}}>
  <div style={{flex:1, display:"flex"}}>
    <input
    style={{margin:"auto", height:"34px", borderRadius:"8px", fontSize:"16px", paddingLeft:"15px"}}
      type="text"
      placeholder="*포장단위 당 무게(kg)"
      value={kg}
      onChange={(event) => setKg(event.target.value)}
    />
  </div>
  <div style={{flex:1, display:"flex"}}>
    <input
    style={{margin:"auto", height:"34px", borderRadius:"8px", fontSize:"16px", paddingLeft:"15px"}}
      type="text"
      placeholder="*사이즈(가로x세로x높이)"
      value={size}
      onChange={(event) => setSize(event.target.value)}
    />
  </div>
</div>

<div className='twopart' style={{width:"100%", height:"80px"}}>
  <div style={{flex:1, display:"flex"}}>
    <input
    style={{margin:"auto", height:"34px", borderRadius:"8px", fontSize:"16px", paddingLeft:"15px"}}
      type="text"
      placeholder="*출발지"
      value={departure}
      onChange={(event) => setDeparture(event.target.value)}
    />
  </div>
  <div style={{flex:1, display:"flex"}}>
    <input
    style={{margin:"auto", height:"34px", borderRadius:"8px", fontSize:"16px", paddingLeft:"15px"}}
      type="text"
      placeholder="*도착지"
      value={arrival}
      onChange={(event) => setArrival(event.target.value)}
    />
  </div>
</div>

<div style={{width:"100%", height:"80px", display:"flex"}}>

  <select 
  style={{margin:"auto", width:"957px", height:"38px", borderRadius:"8px", fontSize:"16px", paddingLeft:"10px", color:"grey"}}
  type="text"
  value={incoterms}
  onChange={(event) => {setIncoterms(event.target.value);
  }}>
  <option selected="selected">무역조건을 선택해주세요</option>
  <option>EXW</option>
  <option>FOB</option>
  <option>CFR</option>
  <option>CIF</option>
  <option>DAP</option>
  <option>DDP</option>
  <option>모름</option>
  </select>

</div>
      
      <div style={{width:"100%", height:"140px", display:"flex"}}>
        <textarea 
        style={{margin:"auto", width:"940px", height:"120px", borderRadius:"8px", fontSize:"16px", paddingLeft:"15px", paddingTop:"10px"}}
        placeholder="추가문의사항을 입력해주세요" value={note} type="text" spellCheck="false" maxLength={500} 
              onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                          event.preventDefault();
                          setNote(note + '\n');
                          }
                          }} onChange={(event) => (setNote(event.target.value))}/>
      </div>

      <div style={{width:"100%", height:"30px", display:"flex", paddingTop:"10px"}}>
        <div style={{width:"100%", margin:"auto 0", paddingLeft:"24px", fontSize:"18px"}}>
        <Agreebox checked={agree} onChange={setAgree}>
        <span style={{color:"red"}}>*</span>
          <a style={{textDecoration:"none", color:"white"}} href="https://www.darlilogis.com/term"
        target="_blank"
        rel="noopener noreferrer"> <strong>이용약관</strong></a> 및 
                <a style={{textDecoration:"none", color:"white"}} href="https://www.darlilogis.com/personal"
        target="_blank"
        rel="noopener noreferrer"> <strong>개인정보취급방침</strong></a> 동의(필수).
        </Agreebox>
        </div>
      </div>

      <div style={{width:"100%", height:"30px", display:"flex"}}>
      <div style={{width:"100%", margin:"auto 0", paddingLeft:"24px", fontSize:"18px"}}>
      <Agreebox checked={ad} onChange={setAd}>
          <span style={{color:"red"}}>* </span>
              광고성 메일 수신동의(필수)
      </Agreebox>
      </div>
      </div>

      <div style={{width:"100%", height:"80px", display:"flex"}}>
        <button className='lcl-submit' type='submit' disabled={loading}
        style={{margin:"auto", width:"140px", height:"60px", borderRadius:"8px", fontSize:"22px", fontWeight:"700", backgroundColor:"#0e4ba6", border:"none", color:"white", cursor:"pointer"}}
          >{loading ? '신청중..' : '신청하기'}</button>
      </div>
      
      </form>
      
      </div>

        </div>
    </div>
    );
}
export default Lclform;