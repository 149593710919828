import firebase from "firebase/compat/app";
import "firebase/compat/auth"
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCzDhbbvhBqQ0gzRBw6BfT9hGNx4hmNB9s",
  authDomain: "darlilogistic.firebaseapp.com",
  projectId: "darlilogistic",
  storageBucket: "darlilogistic.appspot.com",
  messagingSenderId: "483272881883",
  appId: "1:483272881883:web:a81fc72b45e0648fb43efe",
  measurementId: "G-RLFK41HK0S"
  };

firebase.initializeApp(firebaseConfig);

export const firebaseInstance = firebase;

export const authService = firebase.auth();
export const dbService = firebase.firestore();