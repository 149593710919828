import blacklogo from '../assets/blacklogo.svg';
import whitelogo from '../assets/whitelogo.svg';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';


const Navigations = () => {


const [color, setColor] = useState(false);
const changeColor = () => {
if (window.scrollY >= 100) {
setColor(true);
} else {
setColor(false);
}
};

window.addEventListener('scroll', changeColor);




return (
  <React.Fragment>
<div className="headerbody">
<div className={color ? 'header header-bg' : 'header'}>
<div className="heady">
<Link to="/">
<img className="app_logo" src={whitelogo} alt="logo" />
</Link>

</div>
</div>
</div>
</React.Fragment>
);
};

export default Navigations;