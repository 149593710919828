import React from "react";
import { Route, Routes } from 'react-router-dom';
import Homes from "../routes/Homes";
import Navigations from "./Navigations";
import Airform from "./Airform";
import Fclform from "./Fclform";
import Lclform from "./Lclform";
import Term from "./Term";
import Personal from "./Personal";
import ScrollToTop from "./ScrollToTop";
  
  const AppRouter = ({ }) => {
    return (
      <div className="dro">
      <ScrollToTop />
      <Navigations />
      <Routes>
      <Route path='/' element={<Homes />}/>
      <Route path='/airform' element={<Airform />}/>
      <Route path='/fclform' element={<Fclform />}/>
      <Route path='/lclform' element={<Lclform />}/>
      <Route path='/term' element={<Term />}/>
      <Route path='/personal' element={<Personal />}/>
      </Routes>
      </div>
      );
    };
    export default AppRouter;